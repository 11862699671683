<template>
  <game-log-list-card />
</template>
<script>
import GameLogListCard from "./components/GameLogListCard.vue";
export default {
  components: {
    GameLogListCard,
  },
};
</script>
