<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Game Logs</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="UserId"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="searchTypeSelect"
        :items="searchTypeItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Search Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="timeSelect"
        :items="timeItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Time"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="typeSelect"
        :items="gameType"
        :loading="$store.state.progress"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="Mode Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Page"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2 ma-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headersOutPlayer"
      :items="$store.state.gameLogs.all.data"
      :items-per-page="pageItemSelect.id"
      hide-default-footer
      :loading="loading"
      :options.sync="options"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <!-- <template v-slot:item.action="{ item }">
        <v-btn color="primary" dark @click="editItem(item)" class="mb-2"
          >Json</v-btn
        >
      </template> -->
      <template v-slot:item.createDate="{ item }">
        <div>{{ new Date(item.createDate * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.fs="{ item }">
        <div>{{ item.fs | formatMoney }}</div>
      </template>
       <template v-slot:item.m="{ item }">
        <div>{{ item.m   }} - {{ item.n   }}</div>
      </template>
      <template v-slot:item.t="{ item }">
        <div>{{ item.t | formatMoney }}</div>
      </template>
      <template v-slot:item.e="{ item }">
        <v-icon small @click="extraProperties(item)">mdiJson</v-icon>
      </template>
      <template v-slot:item.sr="{ item }">
        <v-icon small @click="spinResult(item)">mdiJson</v-icon>
      </template>
      <template v-slot:item.bl="{ item }">
        <div>{{ item.bl | formatMoney }}</div>
      </template>
      <template v-slot:item.lm="{ item }">
        <div v-if="item.lm" style="display:flex;">
          <div style="text-align:center;font-weight:bold;">
            FreeSpin-{{ item.lm.freeSpin }}
          </div>
          <div style="text-align:center;font-weight:bold;">
            Gems-{{ item.lm.gems }}
          </div>
          <div style="text-align:center;font-weight:bold;">
            MiniGame-{{ item.lm.miniGame }}
          </div>
          <div style="text-align:center;font-weight:bold;">
            WonCoin: {{ item.lm.wonCoin | formatMoney }}
          </div>
        </div>
      </template>
      <template v-slot:item.lw="{ item }">
        <div v-if="item.lw" style="display:flex;">
          <div style="text-align: center;font-weight: bold;">
            Level- {{ item.lw.level }}
          </div>
          <div style="text-align: center;font-weight: bold;">
            NextFreeSpin- {{ item.lw.nextFreeSpin }}
          </div>
          <div style="text-align: center;font-weight: bold;">
            NextWonCoin- {{ item.lw.nextWonCoin }}
          </div>
          <div style="text-align: center;font-weight: bold;">
            TargetSpin- {{ item.lw.targetSpin }}
          </div>
          <div style="text-align: center;font-weight: bold;">
            WonCoin: {{ item.lw.wonCoin }}
          </div>
        </div>
      </template>
      <template v-slot:item.u="{ item }">
        <UserDetailCard
          :userId="item.u"
          :pictureUrl="item.pu"
          :userName="item.un"
          :level="item.l"
          :flag="item.f"
        />
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="$store.state.gameLogs.all.totalPage"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialogExtraProperties" max-width="1600px">
      <v-card>
        <v-card-title>
          <span class="headline">Extra Properties</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogExtraProperties = false"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <json-viewer
            name="df"
            :value="itemExtraProperties"
            :expand-depth="5"
            theme="my-awesome-json-theme"
            sort
          ></json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSpinResult" max-width="1600px">
      <v-card>
        <v-card-title>
          <span class="headline">Spin Result</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogSpinResult = false"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <json-viewer
            name="df"
            :value="itemSpinResult"
            :expand-depth="5"
            theme="my-awesome-json-theme"
            sort
          ></json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import "@/assets/css/site.css";
import logType from "@/lib/logType";
import actionType from "@/lib/actionType";
import gameType from "@/lib/gameType";

export default {
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },

  name: "GameLogListCard",
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      dialogExtraProperties: false,
      itemExtraProperties: {},
      dialogSpinResult: false,
      itemSpinResult: {},
      playerIOdialog: false,
      pageCount: 0,
      timeSelect: { id: -1, value: "All" },
      typeSelect: { key: -999, value: "All" },
      searchTypeSelect: { id: -1, value: "All" },
      pageItemSelect: { id: 50, value: "50" },
      loading: false,
      currentPage: 1,
      actionType: actionType,
      gameType: gameType,
      logType: logType,
      options: {},
      detail: {},
      jsonOptions: {
        mode: "code",
      },
      pagination: {
        sortBy: "name",
      },
      searchTypeItems: [
        { id: -1, value: "All" },
        { id: 0, value: "ModeId" },
        //{ id: 1, value: "Server" },
      ],
      timeItems: [
        { id: -1, value: "All" },
        { id: 60 * 30, value: "Last 30 minutes" },
        { id: 60 * 60, value: "Last 1 hour" },
        { id: 60 * 60 * 24, value: "Last 24 hours" },
        { id: 60 * 60 * 24 * 7, value: "Last 7 Days" },
        { id: 60 * 60 * 24 * 30, value: "Last 30 Days" },
        { id: 60 * 60 * 24 * 90, value: "Last 90 Days" },
      ],

      pageItems: [
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
        { id: 10000, value: "10000" },
      ],
      headersOutPlayer: [
        { text: "User Id", value: "u" },
        //{ text: "User Name", value: "un" },
        { text: "Bet Level", value: "b" },
        { text: "Candy", value: "c" },
        { text: "Create Date", value: "createDate" },
        { text: "Free Spin", value: "f" },
        { text: "Flag", value: "fg" },
        { text: "In Game Type", value: "i" },
        { text: "Ip Address", value: "ipAddress" },
        { text: "Level", value: "l" },
        { text: "Level Map", value: "lm" },
        { text: "Level Win", value: "lw" },
        { text: "Mode", value: "m" },
        { text: "Picture Url", value: "pu" },
        { text: "Free Spin Won Coin", value: "fs" },
        { text: "Total Won Coins", value: "t" },
        { text: "Extra Properties", value: "e" },
        { text: "Spin Result", value: "sr" },
        { text: "Win Type", value: "w" },
        // { text: "Action", value: "action" },
        // { text: "Data", value: "d" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    async extraProperties(item) {
      this.itemExtraProperties = item.e;
      this.dialogExtraProperties = true;
    },
    async spinResult(item) {
      this.itemSpinResult = item.sr;
      this.dialogSpinResult = true;
    },
    async initialize() {
      this.loading = true;
      await this.$store.dispatch("gameLogs/getAll", {
        search: this.search,
        time: this.time,
        size: this.pageItemSelect.id,
        searchType: this.searchTypeSelect.id,
        modeType: this.typeSelect.key,
        page: this.currentPage - 1,
        date: this.timeSelect.id,
        options: this.options,
      });
      this.loading = false;
      // this.detail = this.$store.state.gameLogs.all.data[0];
      // this.holdemDialog = true;
    },
    getDifferent(item) {
      return item.v[5] + item.v[8] + item.ss.reduce((a, b) => a + b.v[3], 0);
    },
    async editItem(item) {
      await this.$store.dispatch("gameLogs/getDetail", item.id);
      this.playerIOdialog = true;
    },
    getPosition(ind) {
      switch (ind) {
        case 0:
          return 30;
        case 1:
          return 6;
      }
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>

<style>
.card-p {
  width: 142px;
  height: 155px;
  background-size: 142px 155px;
  background-repeat: no-repeat;
  border-radius: 9.5px;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.lime-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}
.green-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}
.red-card {
  background-image: url(https://bundle.slotbase.net/assets/img/red.png);
}
.orange-card {
  background-image: url(https://bundle.slotbase.net/assets/img/orange.png);
}
.v-tooltip__content {
  opacity: 1 !important;
}
.jv-container {
  background: #0000005c !important;
}
.jsoneditor-field {
  color: #b7b3c3 !important;
}
a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}
div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}
.ace-jsoneditor .ace_variable,
.ace-jsoneditor .ace_gutter-cell {
  color: #fff !important;
}
div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}
.ace-jsoneditor .ace_scroller,
.ace-jsoneditor .ace_gutter {
  background: #000 !important;
}
</style>
